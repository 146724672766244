import React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home.js';
import BlogPage from './pages/blog.js';
import PhotoPage from './pages/photos.js';
import FollowPage from './pages/follow.js';
import BlogArticle from './pages/BlogArticle.js'
import TestingPage from './pages/Testing.js'
import PatreonRedirect from './pages/patreon.js'
import PatreonLoginButton from './pages/PatreonButton';

function App() {
  return (
    <Router>
      <div className="App">
        <Container>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/photos" element={<PhotoPage />} />
            <Route path="/follow" element={<FollowPage />} />
            <Route path="/blog/view" element={<BlogArticle />} />
            <Route path='/testing' element={<TestingPage />} />
            <Route path='/patreon-callback' element={<PatreonRedirect />} />
            <Route path="/patreon" element={<PatreonLoginButton />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;
