import React from 'react';
import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Grid, Container} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation } from 'react-router-dom';

const defaultTheme = createTheme();

const PatreonLoginButton = () => {
  const location = useLocation();
  console.log(location);
  const CLIENT_ID = process.env.REACT_APP_PATREON_CLIENT_ID;
  const REDIRECT_URI = 'https://findinghama.com/patreon-callback';

  const handleLoginClick = () => {
    const patreonOAuthURL = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
    window.location.href = patreonOAuthURL;
  };

  // Assuming you're using React Router, this useEffect will capture the code from the URL when redirected back
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Handle the code here, e.g., send it to your server to exchange for an access token
      console.log('Received code:', code);
    }
  }, [location.search]);

  return (
    <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <Header />
      <main className="pagebody">
        <Grid container justifyContent="center" sx={{ mt: 11 }}>
          <h2>Testing page!</h2>
          <Container sx={{Width:'30%'}}>
            <button variant="contained" onClick={handleLoginClick}>Login with Patreon</button>
          </Container>
        </Grid>
      </main>
    <Footer />
  </ThemeProvider>
    
  );
};

export default PatreonLoginButton;