import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { createTheme, ThemeProvider, CssBaseline, Container, Grid } from '@mui/material';
import '../App.css';
import Markdown from '../components/Markdown.js';

const defaultTheme = createTheme();

function BlogArticle() {
  const { state } = useLocation();

  return (
    <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Container 
          maxWidth="lg" 
          sx={{py: 6}}>
          <Header />
          <main className="pagebody">
            <Grid container sx={{ mt: 5 }}>
              <Markdown className="markdown">{state.fullPost}</Markdown>
            </Grid>
          </main>
          </Container>
          <Footer />
    </ThemeProvider> 
  );
  }

export default BlogArticle;
