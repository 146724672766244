import React from 'react';
import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Grid} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Weather from '../components/weather';

const defaultTheme = createTheme();
const TestingPage = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
        <main className="pagebody">
          <Grid container justifyContent="center" sx={{ mt: 11 }}>
            <h2>Testing page!</h2>
          </Grid>
        </main>
      <Footer />
    </ThemeProvider>
  );
};

export default TestingPage;
